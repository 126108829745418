<template>
  <router-view/>
</template>

<script>

export default {
  created() {
      this.$router.beforeEach((to, from, next) => {

        let language = to.params.lang;

        if (!language) {
            language = this.$i18n.locale;
        }

        this.$i18n.locale = language;
        next();
    })
  }
}


</script>

